
.calculator-title{
  font-family: $fonte-padaro;
  font-size: 40px;
  font-weight: 300;
  text-align: center;
  line-height: 52px;
  color: #000000;
}

.calculator-description{
  font-family: $fonte-padaro;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  line-height: 30px;
  color: #666666;
  float: left;
}

.section-buttons-calculator{
  display: inline;
  text-align: center;
}

.section-buttons-calculator a, a:link, a:hover{
  text-decoration: none;
}

//a, a:link, a:hover{
//  text-decoration: none;
//}

.calculator-button{
  font-family: $fonte-padaro;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  color: #494848;
  padding-top: 0.1em;
  height: 40px;
  background-color: rgba(192, 0, 0, 0.2);
  border-radius: 100px;
  width: 158px;
  margin-bottom: 1em;
  margin-left: 1em;
  border: none;
}


.ativadaodoido{
  background-color: #C00000;
  font-family: $fonte-padaro;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  padding-top: 0.1em;
  height: 40px;
  border-radius: 100px;
  border:none;
  width: 158px;
  margin-bottom: 1em;
  margin-left: 1em;
  outline: 0 !important;
}

#section-buttons-calculator{
  text-align: center;
}

.calculator-button a:visited, a:active, a:hover{
  font-family: $fonte-padaro;
font-size: 22px;
font-weight: 600;
text-align: center;
line-height: 28px;
color: #FFFFFF;
background-color: rgba(192,0,0,0.2);
opacity:1;
}

a:active{
   font-family: $fonte-padaro;
font-size: 22px;
font-weight: 600;
text-align: center;
line-height: 28px;
color: #FFFFFF;
background-color: rgba(192,0,0,0.2);
opacity:1;
}

#calculator-input{
  width: 89px;
  height: 99px;
  background-color: rgba(192,0,0,0.2);
  font-family: $fonte-padaro;
  font-size: 64px;
  font-weight: 300;
  font-style: italic;
  line-height: 87px;
  color: rgba(0,0,0,0.5);
}

#calculator-input::placeholder{
  color: #000000;
  opacity: 100;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
}

#calculator-input-participants-number{
  font-family: $fonte-padaro;
  font-size: 50px;
  font-weight: 300;
  font-style: italic;
  //line-height: 87px;
  color: #000000;
  width: 89px;
  height: 99px;
  border:none;
}

#button-calculator{
  width: 263px;
  height: 56px;
  background-color: #C00000;
  border-radius: 3px;
  //font
  font-family: $fonte-padaro;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 24px;
  color: #FFFFFF;
  float: right;
}


#convidados-icon{
  font-size: 64px;
  line-height: 64px;
  color: #C00000;
}

.calculator-hr{
  width: 100%;
  box-sizing: border-box;
  height: 2px;
  opacity: 0.1;
  border: 1px solid #000000;
}


#back-to-top{
  font-size: 20px;
  & a{
    text-decoration: none;
    text-align: center;
  }
}

.claculator-buttons{
  display: inline;
  list-style: none;
}

.claculator-buttons-secondary{
  display: inline;
  list-style: none;

}


.claculadora2{
  //display: none;
}

#convidados{
  font-family:  $fonte-padaro;
  font-size: 36px;
  font-weight: 300;
  font-style: italic;
  line-height: 49px;
  color: #000000;
}

#total{
  font-family: $fonte-padaro;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  color: #000000;
  opacity: 0.8;
}

#linha{
  box-sizing: border-box;
  height: 1px;
  opacity: 0.1;
  background: #000;
  //margin-left: 1.3em;
}

#produtcts-selected{
  font-family: $fonte-padaro;
  font-size: 40px;
  font-weight: 300;
  text-align: center;
  line-height: 52px;
  color: #000000;
}

#valor{
  width: 71px;
  font-family: $fonte-padaro;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
}
