.carousel,
.item,
.active {
  height: 100%;
}

.carousel-inner {
  height: 100%;
}

.topo-textos{
  top:-20px;
  clear: both;
  height: 0;
}

.img-of-slide{
  width: 100%;
  height: 620px;
  max-height: 650px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.second-text-top{
  padding-top: -20em;
}

.img-of-slide{
  filter: brightness(50%);
}


.carousel, .active {
  height: auto;
}

.carousel-inner {
  height: 100%;
}

.fill {
  width: 100%;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.to-down{
  font-size: 32px;
  color: #fff;
}
#topo-title-head{
  font-family: $fonte-padaro;
  font-size: 36px;
  font-weight: 300;
  color: #FFFFFF;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  position: relative;
  text-align: left;
  width: 60%;
  margin-left: -5em;
}

#topo-text-head{
  opacity: 0.6;
  font-family: $fonte-padaro;
  font-size: 20px;
  font-weight: 300;
  //line-height: 30px;
  color: #FFFFFF;
  width: 60%;
  position: relative;
  text-align: left;
  margin-left: -9em;
}
