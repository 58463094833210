
body {
  margin-top: 50px; /* Required margin for .navbar-fixed-top. Remove if using .navbar-static-top. Change if height of navigation changes. */
  background-color: #fff;  
}

.image-bg-fluid-height,
.image-bg-fixed-height {
  text-align: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}


.img-center {
  margin: 0 auto;
}

section {
  padding: 75px 0;
  background-color: #ffffff;
}

.section-heading {
   margin: 30px 0;
   font-size: 4em;
 }

.section-lead {
  margin: 30px 0;
}

.section-paragraph {
  margin: 30px 0;
}

footer {
  margin: 50px 0;
}

@media(max-width:768px) {
  section {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .section-heading {
    font-size: 2em;
  }
}


.navbar, .navbar-inverse, .navbar-fixed-top .shrink{
  height: 4em;
}

.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form{
  padding-top: 0.5em;
}
