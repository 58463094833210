

.rectangle {
  width: 263px;
  height: 56px;
  border: solid 1px #e64b44;
  text-transform: uppercase;
  //background-color: #C00000;
 // font-family: $fonte-padaro;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 24px;
  color: #FFFFFF;
  margin-right: 1em;
  background-color: rgba(192,0,0,0.55);
  border-radius: 3px;
  outline: 0 !important;
}

.ativado{
  background-color: #e64b44;
}

.product-name{
  font-family: $fonte-padaro;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 25px;
  color: #4A4A4A;
  text-shadow: 0 1px 0 0 #FFFFFF;
}

.product-size, .product-supplier{
  opacity: 0.55;
  font-family: $fonte-padaro;
  font-size: 14px;
  text-align: center;
  font-weight: 300;
  line-height: 18px;
  color: #4A4A4A;
}


.product-box{
  padding-top: 2em;
}

.text-and-price-box{
  padding-top: 1em;
  padding-bottom: 1em;
}