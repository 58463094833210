
@media only screen and (max-width: 40em) {

  .container{
    padding-top: 1.5em;
  }
  .carousel,
  .item,
  .active {
    height: 100%;
    padding-top: 8%;
  }

  #topo-title-head{
    font-family: $fonte-padaro;
    font-size: 18px;
    font-weight: 300;
    color: #FFFFFF;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    position: relative;
    text-align: left;
    width: 110%;
    margin-left: -2em;
    top: 2em;
  }

  #topo-text-head {
    opacity: 0.6;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 10px;
    font-weight: 300;
    color: #FFFFFF;
    width: 90%;
    position: relative;
    text-align: left;
    margin-left: -2em;
    top: 1.5em;
  }

  #button-calculator{
    position: relative;
    margin-right: 1em;
  }

  .footer-address{
    text-align: center;
  }

  #total, #valor{
    margin-left: 2em;
  }

  #valor{
   font-family: "Source Sans Pro", sans-serif;
   font-size: 30px;
   font-weight: 300;
   font-style: italic;
   color: #000000;

   border: none;
 }

 #convidados-icon {
  font-size: 64px;
  line-height: 64px;
  color: #C00000;
  margin-left: 1em;
}
}

@media only screen and (max-width: 40em) and (orientation: landscape){
  .carousel,
  .item,
  .active {
    height: 100%;
    padding-top: 0%;
  }


}

@media only screen and (min-width: 46em){
  //se quebrar o responsivo é aqui
  .form-control{
    //width: 448px;
    //margin-left: -10em;
    //height: 56px;
    width: 100%;
    /* margin-left: -10em; */
    height: 56px;
  }

  .container{
    padding-top: 1.5em;
  }
}


@media only screen and (min-width: 48em){
  //se quebrar o responsivo é aqui
  .navbar-brand{
      //margin-left: -5em;
      margin-left: -84px;
    }

    .img-logo{
      margin-left: -80px;
    }

    .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand{
      margin-left: -8px;
      padding-top: 0px;
    }


    .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form{
      margin-right: -3em;
    }

    .carousel-indicators {
      bottom: 113px;
    }

    .material-icons{
      font-size: 48px;
    }

    .material-icons:hover{
      zoom: 2;
    }

    .material-icons a:active{
      background: none;
    }

    .calculator-hr {
      //width: 86%;
      box-sizing: border-box;
      height: 1px;
      opacity: 0.1;
      background: #000;
      margin-left: 1.3em;
    }

    #calculator-input{
      float: right;
    }

    .bt-calculator{
      padding-top: 8em;
    }

    .calculator-define{
      padding-top: 4em;
    }

    #button-calculator {
      width: 263px;
      height: 56px;
      background-color: #C00000;
      border-radius: 3px;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      line-height: 24px;
      color: #FFFFFF;
      /* float: left; */
      margin-left: 721px;
    }

    .calculator-description{
      margin-left: 1.5em;
      padding-top: 2em;
    }

    .resultado{
      margin-left: 13em;
    }

    #valor-total{
      float: right;
      margin-top: -3em;
    }

    #valor{
      font-family: "Source Sans Pro", sans-serif;
      font-size: 30px;
      font-weight: 300;
      font-style: italic;
      color: #000000;

      border: none;
    }
  #map {
    height: 357px;
    position: relative;
    width: 448px;
    float: right;
  }

  }