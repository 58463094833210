footer{
  background: #fff;
}

.footer-hr{
  box-sizing: border-box;
    height: 1px;
    opacity: 0.1;
    background: #000;
}
#footer-enterprise-idenfier{
  font-family: "San Francisco";
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: #000000;
  text-align: center;
  opacity: 0.25;
}

.footer-copyrigth{
  font-family: $fonte-padaro;
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  color: #666666;
  padding-top: 1em;
}


.social-nav li{
  display: inline;
  font-size: 25px;
  //margin-left: 0.6em;
  text-align: center;
}

.footer-hr{
  box-sizing: border-box;
  height: 2px;
  opacity: 0.1;
  border: 1px solid #000000;
}

#principalfooter{
  text-transform: uppercase;
  font-weight: 300;
  font-family: $fonte-padaro;
  font-size: 18px;
}

#footer-enterprise-show{
  font-family: $fonte-padaro;
  font-size: 22px;
  font-weight: bold;
  line-height: 19px;
  color: #666666;
  text-align:center;
  text-transform: uppercase;
}

#endereco{
  font-family: $fonte-padaro;
  font-size: 15px;
  text-transform: capitalize;
}

#mail{
  font-family: $fonte-padaro;
  font-size: 15px;
}

#phone-contact{
  font-family: $fonte-padaro;
font-size: 15px;
line-height: 17px;
color: #666666;
}