
.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form{
  font-family: $fonte-padaro;
}
.navbar-inverse{
  background-color: #A30202;
  border-color: #A30202;
  height: 98px;

}

#menu-button-churasco-calculator{
  font-size: 17px;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 100px;
}

a:link, a:visited, a:hover{
  text-decoration: none
  
}

.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus{
  opacity: 0.8;
}

a:active{
  background-color: rgba(192,0,0,0.8);
  border-radius: 100px;

  //font
  font-family: "Source Sans Pro";
  // font-size: 22px;
  // font-weight: 600;
  text-align: center;
  line-height: 28px;
  color: #FFFFFF;
}

.navbar-inverse .navbar-nav > li > a  {
  color: #fff;
  font-size: 17px;
}
.navbar-inverse .navbar-brand{
  color: #fff;
}
