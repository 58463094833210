
.image-bg-fixed-height {
 // background: url('http://lorempixel.com/g/1920/500/') no-repeat center center scroll;
 background-color: #C00000;
 height: auto;
}


.contact-title{
  font-family: $fonte-padaro;
  font-size: 54px;
  font-weight: 300;
  line-height: 64px;
  color: #FFFFFF;
  text-align: center;
  padding-top: 2em;
}

.contact-button{
  background: transparent;
  font-family: $fonte-padaro;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  position: relative;
  color: #fff;
  margin-left: 2em;
  width: 6em;
  float: right;
  height: 42px;
  border: 1px solid #FFFFFF;

}

.contact-button:hover, #menu-button-churasco-calculator:hover{
  background: #fff;
  font-family: $fonte-padaro;
  color: #C00000;
  font-weight: 700;
}

#got-to-top{
  font-size: 30px;
  padding-bottom: 2em;
  color: #000;
  opacity: 0.5;
}

#back-to-top a, a:hover{
  background: none;
  text-decoration: none;
}

.form-group{
  overflow: hidden;
}

.form-control{
  border-radius: 0%;
  border: none;
  background-color: rgba(255,255,255,0.6);
  //fonte
  font-family: $fonte-padaro;
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  line-height: 22px;
  color: #000000;
  opacity: 100;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;

  bottom: 3em;
}


.form-control::placeholder{
  color: #000000;
  opacity: 100;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
}

.form{
  margin-left: 0;
  padding-right: 33px;
}

.contact-endereco{
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  color: #FFFFFF;
  padding-top: 1em;
  padding-bottom: 2em;
}


.contact-number{
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  color: #FFFFFF;
  padding-top: 1em;
}


//MAPS
#map {
  height: 357px;
  position: relative;
  width: 100%;
}

.maps-frame {
  height: 430px;
  width: 100%;
}

.kd-tabbed-vert.header-links .kd-tabbutton a {
  color: #757575;
  display: inline-block;
  height: 100%;
  padding: 0 24px;
  width: 100%;
}

.kd-tabbed-vert.header-links .kd-tabbutton {
  padding: 0;
}

.kd-tabbed-vert.header-links .kd-tabbutton.selected a {
  color: #03a9f4;
}

.kd-tabbed-vert.header-links .kd-tabbutton a:focus {
  text-decoration: none;
}

p.top-desc {
  padding: 1em 1em .1em 1em;
}

p.bottom-desc {
  padding: 0em 1em 1em 1em;
}